.App {
  text-align: center;
}
.App,#root,.ar-app{
  width:100%;
  height:100%;
}

@font-face {
  font-family: 'montserrat-regular';
  src: url(./assets/fonts/Montserrat-Regular.ttf);
  font-weight: normal;
    font-style: normal;
}
@font-face {
  font-family: 'montserrat-bold';
  src: url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'montserrat-medium';
  src: url(./assets/fonts/Montserrat-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'montserrat-semibold';
  src: url(./assets/fonts/Montserrat-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'playfair-italic';
  src: url(./assets/fonts/PlayfairDisplay-Italic.ttf) format('truetype');
  font-weight: normal;
    font-style: normal;
}
.text-bold{
  font-family: "montserrat-bold";
}
.text-medium{
  font-family: "montserrat-medium";
}
.text-regular{
  font-family: "montserrat-regular";
}
.text-semibold{
  font-family: "montserrat-semibold";
}
.text-left{
  text-align: left;
}
.menu-btn-red{
  color:#ffffff;
  background-image:linear-gradient(134.97deg, #FF384E 0%, #E20019 100%);
  padding:15px;
  font-size:16px;
  margin:10px!important;
  border-radius: 5px;
}
.menu-btn-normal{
  color:#353535;
  background-image: linear-gradient(134.97deg, #F4F4F4 0%, #EAEAEA 100%);;
  padding:15px;
  font-size:16px;
  margin:10px!important;
  border-radius: 5px;
}
.title{
  font-size:18px;
  margin-top:10px;
  margin-bottom:0px;
}
.descr{
  font-size:13px;
  padding:0px 10px;
}
.freccia{
  width:25px;
}
.menu-btn-normal p,.menu-btn-red p{
  line-height: 19px;
}
.menu-btn-normal p,.menu-btn-normal div, .menu-btn-red p, .menu-btn-red div{
  margin:auto;
}
.content-center{
  margin: auto;
}
.slider-el img{
  margin:15px auto;
}
.slider-el .info-img{
  max-width:205px;
}
.info-img-text{
  display: inline!important;
  margin: 0px 3px!important;
  width:21px;
}
.homepage a:hover{
 text-decoration: none;
}
.top-bar{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
padding:10px;}

#popup-info{
  position: absolute;
  width:100%;
  height:100%;
  background-color: #F8F8F8;
  z-index: 9999;
  overflow-x: hidden;
  
}
.circle-contact{
  background-color:#ffffff;
  border-radius: 100%;
  width:250px;
  height:250px;
  margin:25px auto 50px auto!important;
  padding:20px 20px;
}
.size-16{
  font-size:16px!important;
  line-height:19px;
}
.size-18{
  font-size:18px!important;
  line-height:22px;
}
body{
  font-size:13px!important;
}

.no-margin{
  margin:0px!important;
}
.no-padding{
  padding:0px!important;
}
#content-info{
  padding-top:30px;
}
.text-right{
  text-align: right;
}
.close-btn{
  width:15px;
  margin:15px;
  cursor:pointer;
}
.map-div{
  position:absolute;
  width:100%;
  height:100%;
}

#filtro{
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
  width:240px;
  position: absolute;
  bottom:15px;
  text-align: left;
  padding:10px;
  height:45px;
  left:15px;  
}
.ar-app .cont-ar{
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.arrow-filtro-down,.arrow-filtro-up{
  text-align:right;
  cursor: pointer;
  width:12px;
  height:7px;
  background-size:contain;
}
.arrow-filtro-down{
  background-image: url('/assets/icon/down.png');
 
}
.arrow-filtro-up{
  background-image: url('/assets/icon/up.png');

}
#filtro p{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size:12px;
   line-height:25px;
}
.filtro-list{
  border-top: 1px solid #E6E6E6;
  height:40px;
  margin:auto;
  cursor: pointer;
}
.open-filter{
  height:auto!important;
}
.check,.icon-filter{
  width:15px;
  text-align:right;
  cursor: pointer;
}
.check{
  background-image: url('/assets/icon/check.png');
  width:15px;
  height:11px;
  background-size: contain;
}

.filtro-list span img{
  margin-right:10px;
}

.info-mappa{

  width:150px;
  text-align: left;
  overflow: hidden;
}

.info-mappa p{
  color:#353535;
  font-size:12px;
  font-family: "montserrat-bold";
  margin-top:10px;
}
.icon-info{
  width:10px;
}
.info-mappa-cat{
  font-size:10px;
  margin-left:10px;
  font-family:"montserrat-bold";
}
.swipe-icon{	
  height: 3px;
  margin: auto;
  width: 31px;
  border-radius: 1.5px;
  background-color: #D8D8D8;

}
.slick-dots{
  bottom:0px!important;
}
.magic-dots.slick-dots li.slick-active button:before{
  color:#E20019!important;
}
.swipe{
width: 100%;
padding: 11px 0px;
margin-bottom:15px;
clear:both;
border-radius: 10px 10px 0 0;
background-color: #F7F7F7;
position: absolute;
top:0;
left:0;
}

#anteprima{
  border-radius: 10px 10px 0 0;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  padding:11px 1rem 1.25rem 1rem;
  position: absolute;
  width: 100%;
  z-index:800;
}
#content-anteprima{
  background-color: #ffffff;
  z-index:800;
  margin-top:25px;
}
.image-anteprima{
  float:left;
  width:50%;
}
.descr-anteprima{
  float:right;
  width:50%;
  color:#000000;
  text-align:left;
}
.distance{
  padding-left:10px;
}
.ar-content{
  width:100%;
  height:100%;
  z-index:900;
  background-color: #FFFFFF;
  text-align:left!important;
  position: absolute;
  overflow: auto;
}
.ar-content .close-map, .ar-content #filtro{
  display: none;
}
.ar-content .map-div{
  width:100%;
  height:300px;
  position: unset;
}

.ar-content h1{
  font-size:32px;  
  line-height: 34px;
}
.ar-content-info{
  padding:10px;
}
.ar-content .header-img{
  width:100%;
}
.ar-content .descr{
  color: #353535;
  font-size: 15px;
  line-height: 22px;
  padding:0px;
}
.ar-content .nav-tabs{
  border:none;
}
.ar-content .nav-tabs .nav-link{
  font-family:"montserrat-bold";
  line-height: 22px;
  font-size:15px;
  color: #353535;
}
.ar-content .nav-tabs .nav-link.active{
  color: #353535!important;
  border-bottom: 2px solid #E20019;
  border-top:none;
  border-left:none;
  border-right:none;
}
.ar-content hr{
  color: #E6E6E6;
}
.map-link{
  float:right; 
}
.mappa-content{
  margin-bottom:40px;
}

.map-link a{
  color: #E20019;
  font-size: 12px;
  line-height: 15px;
  margin-left:10px;
}

.img-gallery{
  width:100%;
  margin-top:10px;
  cursor: pointer;
}
.foto-section{
  margin-top:30px;
  margin-bottom:50px;
}

.close-color{
  width:36px;
  position:absolute;
  cursor: pointer;
  right:10px;
  top:10px;
}

.read-more{
  color:#E20019;
  font-size:15px;
  line-height: 22px;
  cursor: pointer;
}
.ar-content .info-mappa-cat{
  font-size: 12px;
  margin-left:12px;
  font-family:"montserrat-bold";
}
.ar-content .icon-info{
  width:15px;
}
.ar-content .category-cont{
  margin-bottom:10px;
}

/* Footer */
.copyright{
  text-align:left;
}
#footer{
  border-top:1px solid #E6E6E6;
  padding-top:10px;
  font-size:12px; 
  font-family: "montserrat-regular";
}

#footer a{
  color:#000000!important;
  font-family: "montserrat-regular";
}

#footer img{
  width:20px;
  vertical-align: bottom;
}
#footer .row{
  margin:0px;
}


/** error **/
.error-container{
  background-color:rgba(205,44,39,0.7);
		border-radius: 0 0 10px 10px;
		top: 0;
		left: 0;
    padding: 1.25rem;
    color:#ffffff;
}

/** audio player **/
.rhap_container {
  border-radius: 5px!important;
  background-color: #F4F4F4!important;
  margin-bottom:10px;
}
.rhap_current-time{
  font-family:"montserrat-bold";  
  color: #E20019!important;
  line-height: 22px;  
  font-size: 13px;
}
.rhap_total-time{
  font-size: 13px;
  line-height: 22px; 
  color:#353535!important;
  font-family:"montserrat-regular"
}
.rhap_play-pause-button{
  color: #E20019!important;
  overflow: initial!important;
}
.rhap_progress-filled{
  background-color: #E20019!important;
}

/** MAPS **/
.gm-style-mtc{
left:50px!important;
}

/* Per il dialogo di a-frame */

.a-dialog {
  border-radius: .85rem;
  box-shadow: .125rem .125rem .85rem 0 rgba(0,0,0,.68);
}

.a-dialog-button {
  border-radius: .25rem;
}

.a-dialog-ok-button,
.a-dialog-allow-button {
  background-color: #3faff9;
  color: #fff;
}

.a-dialog-deny-button {
  background-color: #fff;
  color: #273238;
}